<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">แบบสอบถามการประชาคมท้องถิ่น</div>
          <div class="t1" style="margin: 5px">เพื่อรับฟังความคิดเห็นต่อร่างแผนพัฒนาท้องถิ่น</div>
          <div class="t1" style="margin: 5px">เทศบาลนครนครศรีธรรมราช</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row mb-2 text-start">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เพศ</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row mt-2 justify-content-center">
                    <div class="col-4">
                      <label class="form-check form-check-custom form-check-solid">
                        <Field type="radio" name="gender" class="form-check-input" value="ชาย"
                          v-model="data_register.gender" />

                        <span class="form-check-label gender" for="kt_roles_select_all">ชาย <img :src="iconMale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                    <div class="col-4">

                      <label class="form-check form-check-custom form-check-solid">
                        <Field type="radio" name="gender" class="form-check-input" value="หญิง"
                          v-model="data_register.gender" />
                        <span class="form-check-label gender" for="kt_roles_select_all">หญิง <img :src="iconFemale"
                            class="icon" alt="" /></span>
                      </label>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container mt-3">
                    <div class="fv-help-block">
                      <ErrorMessage name="gender" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">วันเดือนปีเกิด</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row">
                    <div class="col-3">
                      <Field as="select" name="day" class="form-control form-control-solid" v-model="data_register.day">
                        <option value="" disabled selected>วัน</option>
                        <option v-for="index in 31" :key="index" :value="index">
                          {{ index }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="day" />
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <Field as="select" name="month" class="form-control form-control-solid"
                        v-model="data_register.month">
                        <option value="" disabled selected>เดือน</option>
                        <option v-for="n in monthNamesThai" :key="n.id" :value="n.id">
                          {{ n.name }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="month" />
                        </div>
                      </div>
                    </div>

                    <div class="col-4">
                      <Field as="select" name="year" class="form-control form-control-solid"
                        v-model="data_register.year">
                        <option value="" disabled selected>ปี (พ.ศ.)</option>
                        <option v-for="(n, index) in setRange()" :key="index" :value="n">
                          {{ n }}
                        </option>
                      </Field>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="year" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ระดับการศึกษา</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="edu_level" class="form-control  form-control-solid"
                    @change="checkedu_level($event)" v-model="data_register.edu_level">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="ต่ำกว่าประถมศึกษา">ต่ำกว่าประถมศึกษา</option>
                    <option value="ประถมศึกษา">ประถมศึกษา</option>
                    <option value="มัธยมศึกษา">มัธยมศึกษา</option>
                    <option value="ปริญาตรี">ปริญาตรี</option>
                    <option value="สูงกว่าปริญาตรี">สูงกว่าปริญาตรี</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="edu_level" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="edu_status">
                  <Field type="text" name="edu_level_other" class="form-control  form-control-solid"
                    placeholder="ระดับการศึกษา" v-model="data_register.edu_level_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="edu_level_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">สถานะผู้ตอบแบบสอบถาม</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="type" class="form-control  form-control-solid" @change="checktype($event)"
                    v-model="data_register.type">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="หน่วยงานภาครัฐ">หน่วยงานภาครัฐ</option>
                    <option value="หน่วยงานภาคเอกชน">หน่วยงานภาคเอกชน</option>
                    <option value="หน่วยงานรัฐวิสาหกิจ">หน่วยงานรัฐวิสาหกิจ</option>
                    <option value="ภาคประชาสังคม เช่น อสม./อปพร./กลุ่มอาชีพ/คณะกรรมการกองทุนชุมชน/คณะกรรมการชุมชน">
                      ภาคประชาสังคม เช่น อสม./อปพร./กลุ่มอาชีพ/คณะกรรมการกองทุนชุมชน/คณะกรรมการชุมชน</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="type" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="type_status">
                  <Field type="text" name="type_other" class="form-control  form-control-solid"
                    placeholder="สถานะผู้ตอบแบบสอบถาม" v-model="data_register.type_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="type_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ที่อยู่</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="address" class="form-control  form-control-solid"
                    v-model="data_register.address">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="เขต 1">เขต 1</option>
                    <option value="เขต 2">เขต 2</option>
                    <option value="เขต 3">เขต 3</option>
                    <option value="เขต 4">เขต 4</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                </div>

              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ความคิดเห็นต่อ (ร่าง) แผนพัฒนาท้องถิ่น
                  (พ.ศ. 2566 - 2570) ทบทวน ครั้งที่ 1/2565 ฉบับเพิ่มเติม ครั้งที่ 5/2567
                  ของเทศบาลนครนครศรีธรรมราช</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="comment" class="form-control  form-control-solid"
                    @change="checkcomment($event)" v-model="data_register.comment">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="เห็นด้วย">เห็นด้วย</option>
                    <option value="ไม่เห็นด้วย">ไม่เห็นด้วย</option>
                    <option value="อื่นๆ">อื่นๆ (Other)</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="comment" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="comment_status">
                  <Field type="text" name="comment_other" class="form-control  form-control-solid"
                    placeholder="ความคิดเห็น" v-model="data_register.comment_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="comment_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>






              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ส่งแบบสอบถาม</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import json from "@/service/DataStore.json";
import useSweetalert from "@/service/sweetalert2";
import useRegister from "@/service/api/register";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    let edu_status = ref(false);
    let type_status = ref(false);
    let comment_status = ref(false);
    const items = ref(json);
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const data_register = store.getters.getRegister;
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();

    const register = Yup.object().shape({
      gender: Yup.string().required("กรุณาเลือก เพศ").label("เพศ"),
      day: Yup.string().required("กรุณาเลือก วัน"),
      month: Yup.string().required("กรุณาเลือก เดือน"),
      year: Yup.string().required("กรุณาเลือก ปี"),
      edu_level: Yup.string().required("กรุณาเลือก ระดับการศึกษา").label("ระดับการศึกษา"),
      edu_level_other: Yup.string()
        .when("edu_level", {
          is: (edu_level) => edu_level === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก ระดับการศึกษา"),
        }).label("ระดับการศึกษา"),
      type: Yup.string().required("กรุณาเลือก สถานะผู้ตอบแบบสอบถาม").label("สถานะผู้ตอบแบบสอบถาม"),
      type_other: Yup.string()
        .when("type", {
          is: (type) => type === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก สถานะผู้ตอบแบบสอบถาม"),
        }).label("สถานะผู้ตอบแบบสอบถาม"),
      address: Yup.string().required("กรุณาเลือก ที่อยู่").label("ที่อยู่"),
      comment: Yup.string().required("กรุณาเลือก ความคิดเห็น").label("ความคิดเห็น"),
      comment_other: Yup.string()
        .when("comment", {
          is: (comment) => comment === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก ความคิดเห็นอื่นๆ"),
        }).label("ความคิดเห็น"),

    });

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    function checkedu_level(event) {
      if (event.target.value === "อื่นๆ") {
        edu_status.value = true;
      } else {
        edu_status.value = false;
      }
    }

    function checktype(event) {
      if (event.target.value === "อื่นๆ") {
        type_status.value = true;
      } else {
        type_status.value = false;
      }
    }

    function checkcomment(event) {
      if (event.target.value === "อื่นๆ") {
        comment_status.value = true;
      } else {
        comment_status.value = false;
      }
    }




    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    // const monthNamesThai = ref([
    //   { id: "01", name: "January" },
    //   { id: "02", name: "February" },
    //   { id: "03", name: "March" },
    //   { id: "04", name: "April" },
    //   { id: "05", name: "May" },
    //   { id: "06", name: "June" },
    //   { id: "07", name: "July" },
    //   { id: "08", name: "August" },
    //   { id: "09", name: "September" },
    //   { id: "10", name: "September" },
    //   { id: "11", name: "November" },
    //   { id: "12", name: "December" },
    // ]);


    const setRange = () => {
      let year = moment().year() + 543;
      let value = [];
      let i;
      for (i = year; i >= year - 100; i--) {
        value.push(i);
      }
      return value;
    };
    const setday = (day, month, year) => {
      let y = year;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {

      values.birthday = setday(values.day, values.month, values.year);
      store.dispatch(SET_REGISTER, values);
      // router.push({ name: "Register_3" });

      console.log(store.getters.getRegister);

      Sconfirm("ยืนยัน การส่งแบบสอบถาม", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ขอบคุณที่ร่วมแสดงความคิดเห็น", "success", "ตกลง").then(() => {
                  liff.ready.then(() => {
                    liff.closeWindow();
                  });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      monthNamesThai,
      setday,
      setRange,
      uniqueProvince,
      checkedu_level,
      edu_status,
      checktype,
      type_status,
      checkcomment,
      comment_status,
      color,
      isLoading
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 20px !important;
  /* adjust the width of the image */
  height: 20px !important;
  /* adjust the height of the image */
  margin-right: 5px;
  /* add some space between the image and the label text */
}
</style>
